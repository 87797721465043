import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AccountState } from '@shared/states/account.state';
import { Selectors } from '@shared/states/app.selectors';
import { GetPaymentCard } from '@shared/states/billing.actions';
import { BillingState } from '@shared/states/billing.state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthState } from '@shared/states/auth.state';

@Component({
  selector: 'zef-sca-required',
  templateUrl: './sca-required.dialog.html',
  styleUrls: ['./sca-required.dialog.scss'],
})
export class ScaRequired {
  readonly dialogTitle = $localize`:dialog title@@zef-i18n-00166:Payment authentication required`;

  @Select(AccountState.teamLogo)
  teamLogo$!: Observable<string>;

  @Select(Selectors.teamName)
  teamName$!: Observable<string>;

  @Select(AccountState.isTeamOwner)
  isTeamOwner$!: Observable<boolean>;

  @Select(BillingState.paymentPrice)
  paymentPrice$!: Observable<number>;

  @Select(BillingState.isPaymentExpired)
  isPaymentExpired$!: Observable<boolean>;

  paymentCard$ = this.store
    .select(BillingState.paymentCard)
    .pipe(tap((data) => (!data ? this.store.dispatch(new GetPaymentCard()) : null)));

  constructor(
    private dialogRef: MatDialogRef<ScaRequired>,
    private store: Store,
  ) {}

  gotoPeople() {
    if (this.store.selectSnapshot(AuthState.authScope)) {
      this.store.dispatch(new Navigate(['/login'], { redirect: '/settings/users' }));
    } else {
      this.store.dispatch(new Navigate(['/settings/users']));
    }

    this.dialogRef.close();
  }

  gotoBilling(fragment = null) {
    const action = this.store.selectSnapshot(AuthState.authScope)
      ? new Navigate(['/login'], { redirect: '/settings/billing' })
      : new Navigate(['/settings/billing'], undefined, { fragment });

    this.store.dispatch(action);
    this.dialogRef.close();
  }
}
