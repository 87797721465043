/**
 * Prices module for plans app.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { SplitPrice } from '@plans/shared/pipes/split-price.pipe';

import { PriceDecimal } from '@plans/shared/components/price-decimal/price-decimal.component';

@NgModule({
  bootstrap: [],
  declarations: [SplitPrice, PriceDecimal],
  exports: [PriceDecimal],
  imports: [SharedModule],
  providers: [],
})
export class PricesModule {}
