/**
 * Team info item with team setup.
 *
 * @unstable
 */

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'usage-icon',
  templateUrl: './usage-icon.component.html',
  styleUrls: ['./usage-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsageIcon {
  public iconClasses = '';

  @Input() icon: string = 'settings';
  @Input() colorClass: string = '';
  @Input() name: string = '';
  @Input() percent: number = 50;
  @Input() type: string = 'spinner';
  @Input() size: string = 'normal';

  constructor() {}
}
