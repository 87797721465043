/**
 * Makes an array of two values depending on dot in nimber.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitPrice',
})
export class SplitPrice implements PipeTransform {
  constructor() {}

  public transform(price: string | number): string[] {
    if (!price || price === '0' || price === 0) {
      return ['0', null, '€'];
    }

    const currency = price.toString().substr(-1);

    const temp: string[] = price.toString().split(',');

    return [
      temp[1] ? temp[0] : temp[0].substr(0, temp[0].length - 1),
      temp[1] ? temp[1].substr(0, temp[1].length - 1) : null,
      currency,
    ];
  }
}
