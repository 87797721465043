/**
 * Common module for the billing quota.
 *
 * @stable
 */

import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { UsageIcon } from '@home/shared/components/usage-icon/usage-icon.component';

import { ScaRequired } from '@home/shared/dialogs/sca-required/sca-required.dialog';
import { TrialExpired } from '@home/shared/dialogs/trial-expired/trial-expired.dialog';
import { UnlockAnswers } from '@home/shared/dialogs/unlock-answers/unlock-answers.dialog';
import { PaymentFailed } from '@home/shared/dialogs/payment-failed/payment-failed.dialog';

import { PricesModule } from '@plans/shared/modules/prices.module';

@NgModule({
  bootstrap: [],
  declarations: [UsageIcon, ScaRequired, TrialExpired, UnlockAnswers, PaymentFailed],
  exports: [UsageIcon],
  imports: [SharedModule, PricesModule],
  providers: [],
})
export class BillingModule {}
