<zef-dialog
  *ngIf="data$ | async as data"
  [title]="dialogTitle"
  [logo]="teamLogo$ | async"
  [logoType]="'square'"
  [label]="headerSubtitle$ | async"
>
  <div dialog-content class="mat-typography z-fx-col z-fx-center-baseline z-fx-gap-16">
    <mat-radio-group #unlockFrom="matRadioGroup" class="z-fx z-fx-w100">
      <mat-radio-button
        class="z-fx-50"
        value="licence"
        [checked]="data.answersAvailable > 0"
        [disabled]="data.answersAvailable === 0"
        color="primary"
        i18n
      >
        Monthly Responses
      </mat-radio-button>
      <mat-radio-button
        class="z-fx-50"
        value="extra"
        [checked]="data.answersAvailable === 0 && data.answersExtra > 0"
        [disabled]="data.answersExtra === 0"
        i18n
        color="primary"
      >
        Extra Responses
      </mat-radio-button>
    </mat-radio-group>

    <div class="z-fx z-fx-w100" style="margin-top: -16px">
      <p [class.disabled]="data.answersAvailable === 0" class="zef-color-responses z-fx-50" style="margin-left: 28px">
        <strong>{{ data.answersAvailable }}</strong>
        <ng-container i18n>remaining this month</ng-container>
      </p>
      <p [class.disabled]="data.answersExtra === 0" class="zef-color-responses z-fx-50" style="margin-left: 28px">
        <strong>{{ data.answersExtra }}</strong>
        <ng-container i18n>available until used</ng-container>
      </p>
    </div>

    <ng-container *ngIf="data.status === 'canUnlockAll'">
      <div class="zef-text-h3" i18n>
        Unlock
        <span class="zef-color-responses">{{ data.answersLocked }}</span>
        {data.answersLocked, plural, =1 {Response} other {Responses}}
      </div>
    </ng-container>

    <ng-container *ngIf="data.status === 'canUnlockSome'">
      <div class="zef-text-h3" i18n>
        Unlock first
        <span class="zef-color-responses">{{ data.answersAvailable }}</span>
        <
        {data.answersAvailable, plural, =1 {Response} other {Responses}}
        of {{ data.answersLocked }}
      </div>
      <button
        routerLink="/plans"
        fragment="extra_answers"
        mat-raised-button
        i18n
        matDialogClose
        color="responses"
        [disabled]="(isTeamOwner$ | async) === false"
      >
        Get more Responses
      </button>
      <div *ngIf="(isTeamOwner$ | async) === false">
        <ng-container i18n>Your org’s Owner can buy more Responses.</ng-container>
        <a routerLink="/settings/users" matDialogClose i18n>See People</a>
      </div>
    </ng-container>

    <ng-container *ngIf="data.status === 'cannotUnlockAny'">
      <div class="zef-text-h3" i18n>You’re over your max Responses limit.</div>
      <div i18n>Increase your Responses limit to unlock your locked Responses.</div>
    </ng-container>
  </div>

  <ng-container dialog-actions>
    <button mat-raised-button matDialogClose i18n color="secondary">Cancel</button>
    <button
      *ngIf="data.status === 'canUnlockAll'"
      mat-raised-button
      matDialogClose
      i18n
      color="primary"
      [disabled]="(isTeamAdmin$ | async) === false"
      (click)="unlockAnswers(unlockFrom.value)"
    >
      Unlock Responses
    </button>

    <ng-container *ngIf="data.status === 'canUnlockSome'">
      <button
        mat-raised-button
        matDialogClose
        color="primary"
        i18n
        [disabled]="(isTeamAdmin$ | async) === false"
        (click)="unlockAnswers(unlockFrom.value)"
      >
        Unlock Responses
      </button>
    </ng-container>

    <ng-container *ngIf="data.status === 'cannotUnlockAny'">
      <button
        routerLink="/plans"
        fragment="extra_answers"
        i18n
        mat-raised-button
        matDialogClose
        color="responses"
        [disabled]="(isTeamOwner$ | async) === false"
      >
        Get more Responses
      </button>
    </ng-container>
  </ng-container>

  <ng-container dialog-caption>
    <div
      *ngIf="data.status !== 'cannotUnlockAny' && (isTeamAdmin$ | async) === false"
      class="zef-text-p2 zef-text-light zef-text-align-right"
    >
      <ng-container i18n>Your org’s Owner or Admin can unlock Responses.</ng-container>
      <a routerLink="/settings/users" matDialogClose i18n>See People</a>
    </div>
    <div
      *ngIf="data.status === 'cannotUnlockAny' && (isTeamOwner$ | async) === false"
      class="zef-text-p2 zef-text-light zef-text-align-right"
    >
      <ng-container i18n>Your org’s Owner can buy more Responses.</ng-container>
      <a routerLink="/settings/users" matDialogClose i18n>See People</a>
    </div>
  </ng-container>
</zef-dialog>
