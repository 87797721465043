import { Component, Inject } from '@angular/core';

import { Select, Store } from '@ngxs/store';

import { Observable, of, combineLatest } from 'rxjs';

import { AccountState } from '@shared/states/account.state';
import { UnlockAnswers as UnlockAnswersAction } from '@shared/states/billing.actions';

import { LockedResource, UnlockStatus } from '@shared/models/billing.model';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ItemSearchHelper } from '@shared/services/item-search-helper.service';
import { BillingState } from '@shared/states/billing.state';
import { map } from 'rxjs/operators';
import { usageRemainingLicense } from '@shared/states/billing.functions';

@Component({
  selector: 'unlock-answers',
  templateUrl: './unlock-answers.dialog.html',
  styleUrls: ['./unlock-answers.dialog.scss'],
})
export class UnlockAnswers {
  @Select(AccountState.isTeamAdmin)
  readonly isTeamAdmin$!: Observable<boolean>;

  @Select(AccountState.isTeamOwner)
  readonly isTeamOwner$!: Observable<boolean>;

  @Select(AccountState.teamLogo)
  teamLogo$: Observable<string>;

  readonly dialogTitle = $localize`:dialog title@@zef-i18n-00161:Unlock Responses`;

  readonly headerSubtitle$ =
    this.source.sourceType === 'copy-paste'
      ? of('Copy-Paste')
      : this.source.sourceType === 'import'
        ? of(this.source.lockSource)
        : this.source.sourceType === 'list'
          ? of(this.source.sourceName)
          : this.is.getItemName(this.source.sourceType, this.source.lockSource);

  readonly data$: Observable<{
    answersAvailable: number;
    answersLocked: number;
    answersExtra: number;
    status: UnlockStatus;
  }> = combineLatest([
    this.store.select(BillingState.answersUsage),
    this.store.select(BillingState.extraAnswersUsage),
  ]).pipe(
    map(([usage, extra]) => {
      const answersAvailable = Math.max(0, usage.count - usage.processed);

      return {
        answersAvailable,
        answersLocked: this.source.lockedAnswers,
        answersExtra: usageRemainingLicense(extra),
        status: !answersAvailable
          ? UnlockStatus.CANNOT_UNLOCK_ANY
          : answersAvailable < this.source.lockedAnswers
            ? UnlockStatus.CAN_UNLOCK_SOME
            : UnlockStatus.CAN_UNLOCK_ALL,
      };
    }),
  );

  constructor(
    private store: Store,
    private is: ItemSearchHelper,
    @Inject(MAT_DIALOG_DATA) readonly source: LockedResource,
  ) {}

  unlockAnswers(from: 'licence' | 'extra'): void {
    this.store.dispatch(
      new UnlockAnswersAction({ sourceKey: this.source.lockSource, sourceType: this.source.sourceType, from }),
    );
  }
}
