import { Store } from '@ngxs/store';

import { Component, Input } from '@angular/core';

import { CurrencyCent } from '@shared/pipes/currency-cent.pipe';

@Component({
  selector: 'price-decimal',
  templateUrl: './price-decimal.component.html',
  styleUrls: ['./price-decimal.component.scss'],
})
export class PriceDecimal {
  begin: string = '';
  decimal: string = '';
  end: string = '';

  readonly cc = new CurrencyCent(this.store);

  @Input()
  set price(price: number) {
    this.formatPrice(price);
  }

  constructor(readonly store: Store) {}

  private formatPrice(price: number): void {
    const testFormat = this.cc.transform(123, ' ', undefined, '0.2').trim();
    const separator = testFormat.replace(/\d/g, '');

    this.begin = this.cc.transform(price, 'EUR', 'symbol').trim();
    const parts = this.begin.match(new RegExp(`^(.*?)(\\${separator}\\d+)(.*)$`));

    if (!parts || parts.length < 3) {
      this.decimal = '';
      this.end = '';
    } else {
      this.begin = parts[1];
      this.decimal = parts[2];
      this.end = parts[3] || '';
    }
  }
}
